import React from 'react';
import About from './About';
import Create from './Create';
// import Footerr from './Footerr';
import Hero from "./Hero";
import Values from './Values';
import What from "./What";

const Home = () => {
  return (
    <div className='opacity-100 overflow-x-hidden'>
      <Hero />
      <What />
      <About />
      <Create />
      <Values />
      {/* <Footerr /> */}
    </div>
  )
}

export default Home;