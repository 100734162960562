import React, { useEffect, useRef} from 'react';
import Aboutt from '../assets/about.png';
import { motion, useAnimation, useInView } from 'framer-motion';



const About = () => {
  const ref = useRef(null)
  const inView = useInView(ref, { once: false })
  const animations = useAnimation();

  useEffect(() => {
    if(inView){
      animations.start({
          opacity: 1,
          transition: {
              ease: 'easeIn',
              duration: 0.9,
              delay: 0.2
            }
      })
  }
  if(!inView){
      animations.start({opacity: 0})
  }
})

  return (
    <div className='flex py-8 px-6 lg:px-0 lg:h-screen h-[54rem] justify-center font-barlow relative'>
        <div className='flex lg:gap-16 gap-6 lg:flex-row flex-col' >
            <img src={Aboutt} alt='' className='h-[27rem] lg:h-full lg:w-full w-screen'/>
            <motion.div className='flex flex-col lg:gap-8 gap-6 lg:mt-24' animate={animations} ref={ref}>
                <span className='text-4xl font-bold tracking-wider'>About Us</span>
                <span className='lg:text-base text-sm font-light tracking-wide lg:w-[28rem] lg:mb-0 mb-4'>
                Eden Venture Group (Eden) is a women-led social impact agency and venture firm with a proven track record in innovating for community action and storytelling to drive behavioural change and empower underserved communities. As a creative sector convener, advocacy group and venture firm, we support brands and organisations (public and private sector) looking to create meaningful impact in the world by providing connections to resources like people, ideas, businesses, talent and networks. We seek to break down barriers and ease burdens on impact makers by mobilising the resources required to effectively deliver their mandate for the public good.
                </span>
                {/* <Link to='/profile'><span className='px-6 py-3 border-none rounded-md bg-[#6100c1] w-32 lg:mt-8 text-[#E2E7E9]/90'>Read More..</span></Link> */}
            </motion.div>
        </div>
    </div>
  )
}

export default About;