import bg1 from '../assets/aset/bg1.png';
import bg2 from '../assets/aset/bg2.png';
import bg3 from '../assets/aset/bg3.png';
import bg4 from '../assets/aset/bg4.png';
import bg5 from '../assets/aset/bg13.png';
import bg6 from '../assets/aset/bg6.png';
import emp from '../assets/aset/emp.png';
import Eden from '../assets/aset/eden.png';


const imageSlide = [
    {
        img: bg1,
        img2: Eden,
        title: 'Connect.  Innovate.  Impact',
    },
    {
        img: bg2,
        img2: emp,
        title: 'Connect.  Innovate.  Impact',
    },
    {
        img: bg3,
        img2: emp,
        title: 'Connect.  Innovate.  Impact',
    },
    {
        img: bg4,
        img2: emp,
        title: 'Connect.  Innovate.  Impact',
    },
    {
        img: bg5,
        img2: emp,
        title: 'Connect.  Innovate.  Impact',
    },
    {
        img: bg6,
        img2: emp,
        title: 'Connect.  Innovate.  Impact',
    }

]

export default imageSlide;