import bg1 from '../assets/aset/bg1.png';
import bg2 from '../assets/aset/bg7.png';
import bg3 from '../assets/aset/bg8.png';
import bg4 from '../assets/aset/bg9.png';
import bg5 from '../assets/aset/bg16.png';
import bg6 from '../assets/aset/bg10.png';
import emp from '../assets/aset/emp.png';
import Eden from '../assets/aset/eden.png';

export const imageSlide2 = [
    {
        img: bg1,
        img2: Eden,
        title: 'Connect.  Innovate.  Impact',
    },
    {
        img: bg2,
        img2: emp,
        title: 'Connect.  Innovate.  Impact',
    },
    {
        img: bg3,
        img2: emp,
        title: 'Connect.  Innovate.  Impact',
    },
    {
        img: bg4,
        img2: emp,
        title: 'Connect.  Innovate.  Impact',
    },
    {
        img: bg5,
        img2: emp,
        title: 'Connect.  Innovate.  Impact',
    },
    {
        img: bg6,
        img2: emp,
        title: 'Connect.  Innovate.  Impact',
    }

]

export const slider = [
    {
        content1: 'Eden Venture Group (EVG) is a venture firm designed by',
        content2: 'women to innovate for behavioural change and social impact.',
        title: 'What we do',
    },
    {
        content1: `"I DON'T WANT TO LIVE IN A WORLD THAT DOESN'T LET`,
        content2: 'THE OTHER HALF OF US DO WHAT WE OUGHT"',
        title: 'melinda gates',
    }

]

