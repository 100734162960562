import React, { useState, useEffect } from 'react';
import { motion} from 'framer-motion';
import imageSlide from './data2';
import {imageSlide2} from './data3';




const Hero = () => {

  const[currentSlide, setCurrentSlide] = useState(0);
  const[active, setActive] = useState(null);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      if(currentSlide===4){
        setCurrentSlide(0)
      }else{
        setCurrentSlide(currentSlide+1)
      }
    },4000)
    return () => clearTimeout(timer)
  }, [currentSlide])

  const bgImageStyle = {
    backgroundImage: `url(${imageSlide[currentSlide].img})`,
    backgroundSize: 'cover',
    height: '100vh',
    width: '100vw',
    transition: 'background 0.3s ease-in',
  }
  const bgImageStyle2 = {
    backgroundImage: `url(${imageSlide2[currentSlide].img})`,
    backgroundSize: 'cover',
    height: '100vh',
    width: '100vw',
    transition: 'background 0.3s ease-in',
  }
  const goToNext = (currentSlide)=>{
    setCurrentSlide(currentSlide)
  }
  const activate = (currentSlide) => {
    setActive(currentSlide)
  }


  return (
    <motion.div className="flex text-white relative h-screen w-screen font-barlow flex-col justify-center  items-center ">
      <div style={bgImageStyle} className='hidden lg:flex absolute top-0 left-0'></div>
      <div style={bgImageStyle2} className='lg:hidden flex absolute top-0 left-0'></div>
      <div className='flex z-50  '>
        <img src={imageSlide[currentSlide].img2} alt='' className='lg:w-[26rem] lg:h-52 w-[20rem] h-32 '/>
      </div>
      
      <span className='w-screen font-semibold flex  items-center justify-center my-10 z-10'><span className='lg:text-7xl text-2xl text-[#E2E7E9]/95'>{imageSlide[currentSlide].title}</span></span>
      <span className='flex lg:z-50 z-10 '>
        {
          imageSlide.map((imageSlide, currentSlide)=>(
            <span key={currentSlide} onClick={event =>{goToNext(currentSlide); activate(currentSlide);}} className={`w-3 h-3 bg-[#6100c1] ml-3 cursor-pointer ${active === currentSlide && "bg-white"}`}></span>
          ))
        }
      </span>
    </motion.div>
  )
}

export default Hero;




